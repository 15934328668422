import "./App.css";
import React from "react";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer";
import Home from "./components/home";
import { Routes, Route} from "react-router-dom";
import TCE from "./components/tce";
import Pmonboarding from "./components/pmonboarding";
import Hero from "./components/hero";
import Resource from "./components/resource";
import OnboardingList from "./components/onboardinglist";
import PasswordReset from "./components/passwordreset";
import Phaonboarding from "./components/phaonboarding";
import UniversalTracking from "./components/universaltracking";
import ITP from "./components/itp";
import Upcoming from "./components/upcoming";
import OnboardingReport from "./components/onboardingreport";
// import RegisterModal from "./components/register";
import AuthContext from "./context/authprovider";
import { useContext } from "react";
import NNMeeting from "./components/nnmeeting";
import AwardeeMeeting from "./components/awardeemeeting";
import MentholMeeting from "./components/mentholmeeting";
import EventResource from "./components/eventresource";
import LssMeeting from "./components/lssmeeting";
import LssRegistrants from "./components/lssregistrants";
import CoP from "./components/cop";
import NPMCOP from "./components/newpmcop";
import SBW from "./components/sbw";
import NPMSBW from "./components/npmsbw";
import PSESBW from "./components/psesbw";
import LERSBW from "./components/lersbw";
import HESBW from "./components/hesbw";
import TlApproval from "./components/tlapproval";
import PMOnboardingSurvey from "./components/pmonboardingsurvey";
import PHAOnboardingSurvey from "./components/phaonboardingsurvey";
import WebinarSeries from "./components/webinarseries";
import VC from "./components/vcseries";
import LSSCurriculum from "./components/lss/lsscurriculum";
import LSSObjectives from "./components/lss/lssobjectives";
import LSSSummit from "./components/lss/lsssummit";
import LSSProfiles from "./components/lss/lssprofiles";

function App() {
	const { auth } = useContext(AuthContext);
	return (
		<div className="App">
			<Routes>
			<Route
					path="/*"
					element={<Hero title="Site Temporarily Down" />}
				/>
				
{/* RE-ENABLE */}

				{/* <Route path="/" element={<Hero home={true} />} />
				<Route
					path="/upcoming"
					element={<Hero title="Upcoming TCTC Events" />}
				/>
				
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/vcseries"
						element={<Hero title="Video-Conference Series" />}
					/>
				) : (
					<Route path="/vcseries/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/newpmcop"
						element={<Hero title="New Program Manager CoP" />}
					/>
				) : (
					<Route path="/newpmcop/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/tcessentials/*"
						element={<Hero title="Tobacco Control Essentials" />}
					/>
				) : (
					<Route path="/tcessentials/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
					<Route
						path="/pmonboarding/*"
						element={<Hero title="Awardee Onboarding" />}
					/>
				) : (
					<Route path="/pmonboarding/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
					<Route
						path="/phaonboardingsurvey"
						element={<Hero title="PHA Onboarding Survey" />}
					/>
				) : (
					<Route path="/phaonboardingsurvey" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
					<Route
						path="/phaonboarding/*"
						element={<Hero title="PHA Onboarding" />}
					/>
				) : (
					<Route path="/phaonboarding/*" element={<Hero home={true} />} />
				)}

				{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
					<Route
						path="/itp/*"
						element={<Hero title="Individualized Training Plan" />}
					/>
				) : (
					<Route path="/itp/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 6, 7].includes(auth.rightid) ? (
					<Route path="/ut/*" element={<Hero title="Universal Tracking" />} />
				) : (
					<Route path="/ut/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 6].includes(auth.rightid) ? (
					<Route
						path="/onboardingreport"
						element={<Hero title="Onboarding Report" />}
					/>
				) : (
					<Route path="/onboardingreport" element={<Hero home={true} />} />
				)}
				{[1, 3, 6].includes(auth.rightid) ? (
					<Route
						path="/onboardingapproval"
						element={<Hero title="Onboarding Removal Approval" />}
					/>
				) : (
					<Route path="/onboardingapproval" element={<Hero home={true} />} />
				)}
				<Route
					path="/passwordreset/:email/:code"
					element={<Hero title="Password Reset" />}
				/>
				<Route
				path="/cop"
				element={<Hero title="Communities of Practice" />}
			/>
			<Route
					path="/mm"
					element={<Hero title="2025 Menthol Recipient Meeting" />}
				/>
				<Route
					path="/lssregistration"
					element={<Hero title="2023 L&SS Summer School Registration" />}
				/>
				{[1, 2].includes(auth.rightid) ? (
					<Route
						path="/lssregistrants"
						element={<Hero title="LSS Registrants" />}
					/>
				) : (
					<Route path="/lssregistrants" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/lss/*"
						element={<Hero title="Leadership and Sustainability School" />}
					/>
				) : (
					<Route path="/lss/*" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/lss"
						element={<Hero title="Leadership and Sustainability School" />}
					/>
				) : (
					<Route path="/lss" element={<Hero home={true} />} />
				)}
				{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
					<Route
						path="/pmonboardingsurvey"
						element={<Hero title="Awardee Onboarding Survey" />}
					/>
				) : (
					<Route path="/pmonboardingsurvey" element={<Hero home={true} />} />
				)}

				<Route path="/*" element={<Hero home={true} />} /> */}
			
{/* STOP RE-ENABLE */}

{/* <Route
					path="/webinarseries"
					element={<Hero title="Video-Conference Series" />}
				/> */}
				{
					/* <Route
					path="/nnmeeting"
					element={<Hero title="National Networks Driving Action" />}
				/>*/
					
					/*<Route
					path="/sbw"
					element={<Hero title="Skill-Building Workshops" />}
				/> */
				}
				{/* <Route
					path="/awardeemeeting"
					element={<Hero title="2024 Awardee Meeting" />}
				/> */}
				
				{/* <Route
					path="/npmsbw"
					element={<Hero title="New PM Skill-Building Workshop" />}
				/>
				<Route
					path="/psesbw"
					element={<Hero title="PSE Skill-Building Workshop" />}
				/>
				<Route
					path="/lersbw"
					element={
						<Hero title="Leveraging Essential Relationships Skill-Building Workshop" />
					}
				/>
				<Route
					path="/hesbw"
					element={<Hero title="Health Equity Skill-Building Workshop" />}
				/> */}
				</Routes>
			
			<div className="body-container" style={{ paddingTop: "30px" }}>
				<Routes>
					{/* shutting site down */}
					{/* <Route path="/*" element={<Home />} /> */}

{/* RE-ENABLE */}
					{/* <Route path="/" element={<Home />} />
					<Route path="/upcoming" element={<Upcoming />} />
					
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/tcessentials" element={<TCE />} />
					) : (
						<Route path="/tcessentials" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/vcseries" element={<VC />} />
					) : (
						<Route path="/vcseries" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/newpmcop" element={<NPMCOP />} />
					) : (
						<Route path="/newpmcop" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route
							path="/pmonboardingsurvey"
							element={<PMOnboardingSurvey />}
						/>
					) : (
						<Route path="/pmonboardingsurvey" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route
							path="/tcessentials/conference/:id"
							element={<EventResource />}
						/>
					) : (
						<Route path="/tcessentials/conference/:id" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/tcessentials/:id" element={<Resource />} />
					) : (
						<Route path="/tcessentials/:id" element={<Home />} />
					)}
					{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
						<Route path="/pmonboarding" element={<Pmonboarding />} />
					) : (
						<Route path="/pmonboarding" element={<Home />} />
					)}
					{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
						<Route path="/pmonboarding/:id" element={<OnboardingList />} />
					) : (
						<Route path="/pmonboarding/:id" element={<Home />} />
					)}
					{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
						<Route
							path="/phaonboardingsurvey"
							element={<PHAOnboardingSurvey />}
						/>
					) : (
						<Route path="/phaonboardingsurvey" element={<Home />} />
					)}
					{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
						<Route path="/phaonboarding" element={<Phaonboarding />} />
					) : (
						<Route path="/phaonboarding" element={<Home />} />
					)}

					{[1, 2, 3, 4, 7].includes(auth.rightid) ? (
						<Route path="/itp" element={<ITP />} />
					) : (
						<Route path="/itp" element={<Home />} />
					)}
					{[1, 2, 3, 4, 6, 7].includes(auth.rightid) ? (
						<Route path="/ut" element={<UniversalTracking />} />
					) : (
						<Route path="/ut" element={<Home />} />
					)}
					{[1, 2, 3, 6].includes(auth.rightid) ? (
						<Route path="/onboardingreport" element={<OnboardingReport />} />
					) : (
						<Route path="/onboardingreport" element={<Home />} />
					)}
					{[1, 3, 6].includes(auth.rightid) ? (
						<Route path="/onboardingapproval" element={<TlApproval />} />
					) : (
						<Route path="/onboardingapproval" element={<Home />} />
					)}
<Route path="/mm" element={<MentholMeeting />} />
					
					<Route path="/lssregistration" element={<LssMeeting />} />
					{[1, 2].includes(auth.rightid) ? (
						<Route path="/lssregistrants" element={<LssRegistrants />} />
					) : (
						<Route path="/lssregistrants" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/lss/summit" element={<LSSSummit />} />
					) : (
						<Route path="/lss/summit" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/lss/curriculum" element={<LSSCurriculum />} />
					) : (
						<Route path="/lss/curriculum" element={<Home />} />
					)}

					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/lss/profiles" element={<LSSProfiles />} />
					) : (
						<Route path="/lss/profiles" element={<Home />} />
					)}
					{[1, 2, 3, 4, 5, 6, 7, 8, 10].includes(auth.rightid) ? (
						<Route path="/lss*" element={<LSSObjectives />} />
					) : (
						<Route path="/lss*" element={<Home />} />
					)}

					<Route
						path="/passwordreset/:email/:code"
						element={<PasswordReset />}
					/>
					<Route path="/*" element={<Home />} /> */}


{/* STOP RE-ENABLE */}

					{/* <Route path="/nnmeeting" element={<NNMeeting />} /> */}
					{/* <Route path="/webinarseries" element={<WebinarSeries />} /> */}
					{/* <Route path="/awardeemeeting" element={<AwardeeMeeting />} /> */}
					
					{/* <Route path="/cop" element={<CoP />} /> */}
					{/* <Route path="/npmsbw" element={<NPMSBW />} />
					<Route path="/psesbw" element={<PSESBW />} />
					<Route path="/lersbw" element={<LERSBW />} />
					<Route path="/hesbw" element={<HESBW />} /> */}
					{/*<Route path="/sbw" element={<SBW />} /> */}
				</Routes>
			</div>
			<Footer />
		</div>
	);
}

export default App;
